<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('withdraw2[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    ><van-icon name="./static/icon/task01.png" slot="right" />
    </van-nav-bar>
    <div style="background-color: #191C23;color: #e7e7e7;padding: 10px;font-size: 15px">
      <div style="display: flex;margin-left: 15px">
        <van-icon name="./static/icon/b2.png" size="22"/>
        <div style="margin-left: 5px"> {{ $t("wallet.default[9]") }}({{InitData.usdtinfo.sign}})</div>
      </div>
      <div style="margin-left: 25px;margin-top: 10px"> {{ Number(UserInfo.balance2).toFixed(2) }} </div>
    </div>
      <div class="box">
        <div>
          <van-field
                  v-model="BankInfo.bank_name" readonly
                  :label="$t('bindAccount.fields[3]')"
                  :placeholder="$t('bindAccount.placeholder[3]')"
          />
          <van-field
                  v-model="BankInfo.name" readonly
                  :label="$t('bindAccount.fields[0]')"
                  :placeholder="$t('bindAccount.placeholder[0]')"
          />
          <van-field
                  v-model="BankInfo.card_no" readonly
                  :label="$t('bindAccount.fields[2]')"
                  :placeholder="$t('bindAccount.placeholder[2]')"
          />
          <van-field
                  v-model="BankInfo.phone" readonly
                  :label="$t('bindAccount.fields[1]')"
                  :placeholder="$t('bindAccount.placeholder[1]')"
          />
          <div style="display: flex;background-color: #191C23">
          <van-field v-model="amount"
                     :label="$t('wallet.label[1]')"
                     :placeholder="$t('wallet.placeholder[1]')"
          />
            <van-button color="#F0B80D" size="min" style="width: 100px;height: 30px" @click="setAmount">
              <span style="color: #000">{{$t('sell.label[8]')}}</span></van-button>
          </div>
        </div>
      </div>
        <div style="text-align: center;margin-top: 100px">
          <p class="btn" @click="submitDraw">{{$t('recharge.default[4]')}}</p>
        </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      amount:0,
      BankInfo:{},
    };
  },
  computed: {

  },
  watch: {},
  created() {

    this.$Model.GetUserInfo()
    this.$toast.loading();
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
            this.BankInfo = data['data'][0];
          }
        }
    );
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/withdrawlist");
    },
    setAmount(){
      this.amount = this.UserInfo.balance2
      // this.BankInfo.money = 333
    },
    submitDraw() {
      let postData = this.BankInfo;
      postData['money'] = parseFloat(this.amount).toFixed(2);
      this.$Model.Draw(postData, (data) => {
        // this.isSubmit = false;
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.push("/user");
        }
      });
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
    background-color: #4e51bf;
} */
.PageBox {
  color: #635327;
  background-color: #13171A;
}

.van-nav-bar {
  background-color: #191C23;
}
.van-nav-bar>>>.van-nav-bar__title{
  color:#cccccc;
}
.van-nav-bar>>>.van-nav-bar__arrow {
  color:#cccccc;
}

.PageBox .van-cell{
  background-color: #191C23;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
  color: #cccccc;
}
.PageBox .box {
  width: 95%;
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  margin-top: 5px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: #191C23;
  color: #555;
}


p{
  width: 80px;
}


.btn {
  width: 85%;
  padding: 10px 50px;
  border-radius: 20px;
  background-color: #F0B80D;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin: 15px 30px 30px;
}
</style>
